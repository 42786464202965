<template>
  <div class="h-full flex flex-col px-0 pt-2 pr-2 pb-4">
    <div class="bg-green-400 font-normal text-white px-1 mt-2 rounded-sm rounded-b-none mb-1 justify-between flex">
      <div class="flex items-center">
        <span class="mx-1">PO:M</span>
      </div>
      <div class="flex items-center">
        <ScaleLoader
          :height="20"
          :radius="2"
          v-show="loading"
          class="flex"
          :color="`#FFFFFF`"
        />
      </div>
      <div class="flex flex-row my-auto items-center">
        <input
          type="number"
          class="my-auto rounded-sm border border-blue-100 leading-tight h-4 mx-2 px-2 text-gray-600 text-center placeholder-gray-600 form-input block text-xs"
          placeholder="12345"
          v-model="SEARCH_SPOTID"
          v-on:keyup.enter="GET_PLAYOUT_MOBILE(SEARCH_SPOTID)"
        />

        <button
          class="flex font-normal px-2 mr-1 border border-white my-2 rounded-sm"
          v-on:click.prevent="GET_PLAYOUT_MOBILE(SEARCH_SPOTID)"
        >
          FIND SPOTID
        </button>
      </div>
    </div>
    <div class="flex-col px-1 py-1 leading-7 flex-1 overflow-y-scroll overflow-x-scroll border border-gray-50 bg-gray-100 rounded-sm rounded-t-none max-h-full max-w-full">
      <div class="font-mono text-xs px-1 leading-5">
        <div
          v-for="(k, v) in PLAYOUT_MOBILE"
          :key="k + v"
        >
          <div
            v-for="(x, y) in k"
            :key="x + y"
          >
            <div
              v-for="(n, p) in x"
              :key="n + p"
            >
              <div
                class="my-2"
                v-for="(a, s) in n"
                :key="a + s"
              >
                <table class="table-auto">
                  <thead>
                    <tr>
                      <td class="border-t border-b border-l px-2 py-1 text-center font-semibold whitespace-no-wrap">
                        spotid
                      </td>
                      <td class="border-t border-b px-1 py-1 text-center font-semibold whitespace-no-wrap">
                        {{ s.split("_")[1] }}
                      </td>
                      <td class="border-t border-b px-1 py-1 text-center font-semibold whitespace-no-wrap">
                        wmedia
                      </td>
                      <td class="border-t border-b px-1 py-1 text-center font-semibold whitespace-no-wrap">
                        {{ s.split("_")[3].split(".")[0] }}
                      </td>
                      <td class="border-t border-b px-1 py-1 text-center font-semibold whitespace-no-wrap">
                        {{ s.split(".")[1] }}
                      </td>

                      <td class="border-t border-b px-2 py-1 text-center font-semibold underline whitespace-no-wrap">
                        Date:
                      </td>
                      <td class="border-t border-b px-2 py-1 text-center font-semibold whitespace-no-wrap">
                        {{ p }}.
                      </td>
                      <td class="border-t border-b px-2 py-1 text-center font-semibold whitespace-no-wrap">
                        {{ y }}.
                      </td>
                      <td class="border-t border-b px-2 py-1 text-center font-semibold whitespace-no-wrap">
                        {{ v }}
                      </td>

                      <td class="border-t border-b px-2 py-1 text-center font-semibold whitespace-no-wrap">
                        Total:
                      </td>
                      <td class="border-t border-b border-r px-2 py-1 text-center font-semibold underline whitespace-no-wrap">
                        {{ GET_SUM(a) }}
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td
                        class="border px-1 py-1 text-center whitespace-no-wrap"
                        v-for="(q, w) in a"
                        :key="q + w"
                      >
                        {{ w }}h-{{
                          parseInt(w, 10) + 1 == 24 ? 0 : parseInt(w, 10) + 1
                        }}h
                      </td>
                    </tr>
                    <tr>
                      <td
                        class="border px-2 py-1 text-center"
                        v-for="(q, w) in a"
                        :key="q + w"
                      >
                        {{ q }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { ScaleLoader } from "@saeris/vue-spinners";
export default {
  name: "Playouttrain",
  components: { ScaleLoader },
  beforeMount() {
    this.USER_OBJECT = JSON.parse(localStorage.getItem("USER_OBJECT"));
    this.$store.commit("UPDATE_USER_OBJECT", this.USER_OBJECT);
    this.AUTH_HEADER = JSON.parse(localStorage.getItem("AUTH_HEADER"));
    this.$store.commit("UPDATE_AUTH_HEADER", this.AUTH_HEADER);
    axios.defaults.headers.common["Authorization"] = this.AUTH_HEADER;
  },
  mounted() {},
  unomunted() {},
  data() {
    return {
      USER_OBJECT: {},
      AUTH_HEADER: "",
      PLAYOUT_MOBILE: [],
      SEARCH_SPOTID: null,
      loading: false,
    };
  },
  computed: {},
  methods: {
    GET_PLAYOUT_MOBILE: function (spotid) {
      this.loading = true;
      this.$store.dispatch("GET_PLAYOUT_MOBILE", { spotid }).then(
        (response) => {
          this.PLAYOUT_MOBILE = response.data;
          this.loading = false;
          this.SEARCH_SPOTID = null;
        },
        (error) => {
          this.loading = false;
          this.SEARCH_SPOTID = null;
          if (error.response.status >= 400 && error.response.status <= 499) {
            this.hasError = true;
            localStorage.removeItem("USER_OBJECT");
            localStorage.removeItem("AUTH_HEADER");
            this.$store.commit("UPDATE_USER_OBJECT", null);
            this.$store.commit("UPDATE_AUTH_HEADER", null);
            this.$router.push("/");
          } else if (
            error.response.status >= 500 &&
            error.response.status <= 599
          ) {
            alert("Internal Server Error :(");
          }
        }
      );
    },
    GET_SUM: function (a) {
      let sum = 0;
      for (let [key, value] of Object.entries(a)) {
        if (key) {
          true;
        }
        sum = sum + parseInt(value, 10);
      }
      return sum;
    },
  },
};
</script>
